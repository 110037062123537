import React from 'react';
import logo from './assets/tolnkee_logo.svg';

function App() {
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
      backgroundColor: '#282c34',
    }}>
      <img src={logo} alt="logo"/>
      <h1 style={{
        color: '#FFF',
        fontSize: 20,
        textTransform: 'uppercase',
        fontFamily: 'Lato',
        fontWeight: 'regular',
      }}>
        Soon available...
      </h1>
    </div>
  );
}

export default App;
